<template>
  <div class="container">
    <el-row>
      <content-title :nav="nav"></content-title>
    </el-row>

    <el-row>
      <el-tabs v-model="activeTableName" @tab-click="listTab">
        <el-tab-pane label="用户列表" name="first">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="登录账号">
              <el-input v-model="login_name"></el-input>
            </el-form-item>
            <el-form-item label="手机号">
              <el-input v-model="mobile"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="search('url1')">查询</el-button>
            </el-form-item>
          </el-form>

          <el-table
            :data="user_list"
            :fit="true"
            :cell-style="{ 'text-align': 'center' }"
            v-loading="is_loading"
            :header-cell-style="{ 'text-align': 'center' }"
          >
            <el-table-column label="ID" prop="user_id"></el-table-column>
            <el-table-column label="昵称" prop="nick_name"></el-table-column>

            <el-table-column label="登录账号" prop="login_name">
            </el-table-column>
            <el-table-column label="登录时间" prop="login_time">
            </el-table-column>
            <el-table-column label="所属机构" prop="map.machanism_name">
            </el-table-column>
            <el-table-column
              label="注册时间"
              prop="create_time"
            ></el-table-column>
            <!-- <el-table-column
              label="渠道来源"
              prop="register_platform"
            ></el-table-column> -->
            <el-table-column label="操作" prop="" width="250px;">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.status == 1"
                  size="small"
                  @click="update(scope.row, 3)"
                  type="danger"
                  >冻结
                </el-button>
                <el-button
                  v-if="scope.row.status == 1"
                  size="small"
                  @click="update(scope.row, 2)"
                  type="danger"
                  >限制登录
                </el-button>
                <el-button
                  v-if="scope.row.status == 1"
                  size="small"
                  @click="update(scope.row, 5)"
                  type="danger"
                  >注销
                </el-button>
                <el-button
                  v-if="scope.row.status != 1"
                  size="small"
                  @click="shelve(scope.row)"
                  type="primary"
                  >解冻/解除限制
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :total="user_total"
            :current-page="currentPage"
            @current-change="payPageChange"
          >
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: { firstNav: "机构管理中心", secondNav: "账户管理" },
      activeTableName: "first",
      currentPage: 1,
      is_loading: true,
      login_name: null,
      // 查询
      flowing_no: "",
      user_info: {},
      userVisiable: false,
      studycard_id: null,
      studycard_list: [],
      timeRange: null,
      mobile: null,
      nick_name: "",
      card_id: null,
      title: null,
      mechanism_id: null,
      mechanism_name_list: [],
      payTotal: { settleCash: 0, cashTotal: 0, noSettleCash: 0 },
      user_list: [],
      user_total: 0,
    };
  },
  methods: {
    // getPayTotal() {
    //   let url = "/user/rechargerecord/querySettleCount";
    //   this.$axios
    //     .get(url, {
    //       params: {},
    //     })
    //     .then((res) => {
    //       console.log(res);
    //       this.payTotal = res.data.data;
    //     });
    // },
    searchCouName(query) {
      let url = "/user/masterSetPrice/queryByMessage";
      this.$axios
        .get(url, {
          params: {
            status: 2,
            type: "mechanism_offline",
            categories: "体育运动",
            source: "体育宝",
            pageSize: 10,
            // is_school: false,
            currentPage: 1,
            title: typeof query == "string" ? query : null,
            // master_id: this.master_id? this.master_id:null,
            mechanism_id: this.mechanism_id ? this.mechanism_id : null,
          },
        })
        .then((res) => {
          this.studycard_list = res.data.data.rows;
        })
        .catch((err) => {});
    },
    lookDetails(row) {
      let url = "/user/userInfo/findById";
      this.$axios
        .get(url, {
          id: row.id,
        })
        .then((res) => {
          this.user_info = res.data.data;
          this.userVisiable = true;
        });
    },
    getUserList() {
      this.is_loading = true;
      let url = "/user/user/queryByMessage";
      let data = {
        pageSize: 10,
        currentPage: this.currentPage,
        // status: 1,
        is_teach_paypal: true,
        login_name: this.login_name,
        register_platform: "mechanism_owner",
      };
      this.$axios
        .get(url, {
          params: {
            ...data,
          },
        })
        .then((res) => {
          this.user_list = res.data.data.rows.filter((item) => {
            return item.status != 5;
          });
          this.user_total = res.data.data.total;
          this.is_loading = false;
        });
    },
    search(status) {
      this.currentPage = 1;
      this.getUserList(status);
    },
    downloadPayList(state) {
      this.$axios
        .request({
          method: "get",
          url: "/user/rechargerecord/exportExcel",
          responseType: "blob",
          params: {
            status: state == 1 ? 2 : state,
            pageSize: this.user_total,
            currentPage: 1,
            finished: true,
            is_school: false,
            is_settlement: state == 1 ? true : state == 2 ? false : null,
            mechanism_id: this.mechanism_id ? this.mechanism_id : null,
            nick_name: this.nick_name ? this.nick_name : null,
            studycard_id: this.studycard_id ? this.studycard_id : null,
          },
        })
        .then((res) => {
          console.log("导出", res);
          const link = document.createElement("a");
          let blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          link.style.display = "none";
          //设置连接
          link.href = URL.createObjectURL(blob);
          document.body.appendChild(link);
          //模拟点击事件
          link.click();
        });
    },
    shelve(row) {
      let url = "/user/user/update";
      this.$axios
        .post(url, {
          user_id: row.user_id,
          status: 1,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "warning",
              message: "成功!",
            });
            this.getUserList();
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
    },
    update(row, status) {
      let url = "/user/user/update";
      this.$axios
        .post(url, {
          user_id: row.user_id,
          status: status,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "warning",
              message: "成功!",
            });
            this.getUserList();
          } else {
            this.$message({
              type: "error",
              message: res.data.message,
            });
          }
        });
    },
    // getSchPayment() {
    //   this.$refs.chart2.loading();

    //   let url = "/user/rechargerecord/queryYesterdayCount";
    //   this.$axios
    //     .get(url, {
    //       parmas: {
    //         is_school: true,
    //       },
    //     })
    //     .then((res) => {
    //       let cancelMap = res.data.data.cancelMap;
    //       let payMap = res.data.data.payMap;
    //       let temp = [];
    //       temp.push(["缴费管理", "昨日消课金额", "昨日退款金额"]);

    //       for (let item in payMap) {
    //         if (item != "total") {
    //           temp.push([item, payMap[item], cancelMap[item]]);
    //         }
    //       }
    //       console.log(temp);
    //       this.$refs.chart2.drawRings({
    //         total: [payMap.total, cancelMap.total],
    //         data: temp,
    //       });
    //     });
    // },
    // getOutPayment() {
    //   this.$refs.chart1.loading();

    //   let url = "/user/rechargerecord/queryYesterdayCount";
    //   this.$axios
    //     .get(url, {
    //       parmas: {
    //         is_school: false,
    //       },
    //     })
    //     .then((res) => {
    //       let cancelMap = res.data.data.cancelMap;
    //       let payMap = res.data.data.payMap;
    //       let temp = [];
    //       temp.push(["缴费管理", "昨日消课金额", "昨日退款金额"]);

    //       for (let item in cancelMap) {
    //         if (item != "total") {
    //           temp.push([item, payMap[item], cancelMap[item]]);
    //         }
    //       }
    //       this.$refs.chart1.drawRings({
    //         total: [payMap.total, cancelMap.total],
    //         data: temp,
    //       });
    //     });
    // },
    searchOrgName(query) {
      let url = "/user/mastermechanism/queryMechanismByType";
      this.$axios
        .get(url, {
          params: {
            type: "teach_paypal",
            mechanism_name: query,
            pageSize: 10,
            currentPage: 1,
            status: 2,
          },
        })
        .then((res) => {
          // console.log(res);
          this.mechanism_name_list = res.data.data;
        });
    },
    payPageChange(v) {
      this.currentPage = v;
      this.getUserList();
    },
    finPageChange(v) {
      this.currentPage = v;
      this.getUserList();
    },
    // refPageChange(v) {
    //   this.currentPage = v
    //   this.getUserList(3)
    // },
    chartTab(tab, event) {
      if (tab.index == 0) {
        this.getOutPayment();
      } else if (tab.index == 1) {
        this.getSchPayment();
      }
    },
    listTab(tab, event) {
      this.flowing_no = "";
      this.studycard_id = null;
      this.nick_name = "";
      this.mechanism_name = "";
      this.currentPage = 1;

      if (tab.index == 0) {
        this.getUserList("url1");
      } else if (tab.index == 1) {
        this.getUserList("url2");
      }
    },
  },
  mounted() {
    // this.getOutPayment();
    // this.getPayTotal();
    this.getUserList("url1");
  },
};
</script>

<style>
.payment.el-row .el-col {
  display: flex;
  height: 10rem;
  box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.07);
  padding: 1rem 2.5rem;
}
.payment.el-row .el-col div {
  margin-right: 3rem;
}
.el-row.payment {
  margin-bottom: 3rem;
}
.el-row.payment .el-col div div:first-child {
  font-size: 3rem;
}
.el-row.payment .el-col div.used div:first-child {
  color: #4fc5ea;
}
.el-row.payment .el-col div.unused div:first-child {
  color: #f44c4c;
}
.el-row.payment .el-col div.total div:first-child {
  color: #6c6fbf;
}
.payment .used,
.payment .unused,
.payment .total {
  display: flex;
  height: inherit;
  flex-direction: column;
  align-content: center;
}
.main .el-input {
  width: inherit;
}
.search > *:not(:last-child) {
  margin-right: 1rem;
}
</style>
